<template>
  <div class="flex flex-col items-center">
    <h1 class="heading">Customer Lists.</h1>
    <LoadingSpinner v-if="loading" />
    <table v-else class="max-w-screen-lg min-w-[70%] table-fixed border border-slate-400 text-sm">
      <thead>
        <tr>
          <th class="border border-slate-300" @click="updateOrdering('name')">
            Name
            <span v-if="currentOrdering === 'name'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-name'" class="sort-indicator">▼</span>
            <span v-if="currentOrdering !== '-name' && currentOrdering !== 'name'">▲▼</span>
          </th>
          <th class="border border-slate-300">Founder</th>
          <th class="border border-slate-300" @click="updateOrdering('status')">
            Status
            <span v-if="currentOrdering === 'status'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-status'" class="sort-indicator">▼</span>
            <span v-if="currentOrdering !== '-status' && currentOrdering !== 'status'">▲▼</span>
          </th>
          <th class="border border-slate-300" @click="updateOrdering('datetime_created')">
            Created Date
            <span v-if="currentOrdering === 'datetime_created'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-datetime_created'" class="sort-indicator">▼</span>
            <span
              v-if="
                currentOrdering !== '-datetime_created' && currentOrdering !== 'datetime_created'
              "
              >▲▼</span
            >
          </th>
          <th class="border border-slate-300">Created By</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customerList in lists.list" :key="customerList.id">
          <td>
            <router-link
              class="text-cyan-500"
              :to="{ name: 'AdminCustomerListShow', params: { id: customerList.id } }"
            >
              {{ customerList.name }}
            </router-link>
          </td>
          <td>{{ customerList.founder ? customerList.founder.fullName : 'N/A' }}</td>
          <td>{{ customerList.status }}</td>
          <td>{{ moment(customerList.datetimeCreated).format('MM/DD/YYYY') }}</td>
          <td>{{ customerList.createdByUserName }}</td>
        </tr>
      </tbody>
    </table>
    <CollectionPagination
      :collection="lists"
      @is-loading-status-update="(isLoading) => (loading = isLoading)"
    />
  </div>
</template>

<script>
import moment from 'moment'
import CustomerList from '@/services/customerLists'

import LoadingSpinner from '@/components/LoadingSpinner'
import CollectionPagination from '@/components/inputs/CollectionPagination'

export default {
  name: 'AdminCustomerListIndex',
  components: { LoadingSpinner, CollectionPagination },
  data() {
    return {
      loading: true,
      moment: moment,
      lists: CustomerList.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: {
          ordering: this.$route.query.ordering || '-datetime_created',
        },
      }),
    }
  },
  async created() {
    await this.lists.refresh()
    this.loading = false
  },
  methods: {
    setPaginationQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.lists.pagination.page,
          pageSize: this.lists.pagination.size,
          ordering: this.lists.filters.ordering,
        },
      })
    },
    updateOrdering(ordering) {
      let currentOrdering = this.$route.query.ordering || '-datetime_created'
      if (currentOrdering === ordering) {
        ordering = '-' + ordering
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          page: 1, // Reset to first page
          pageSize: this.lists.pagination.size,
          ordering,
        },
      })
    },
  },
  computed: {
    currentOrdering() {
      return this.$route.query.ordering || '-datetime_created'
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.loading = true
        this.lists.filters.ordering = this.$route.query.ordering || '-datetime_created'
        this.lists.pagination.page = this.$route.query.page || 1
        this.lists.pagination.size = this.$route.query.pageSize || 20
        await this.lists.refresh()
        this.loading = false
      },
    },
    'lists.pagination.page': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'lists.pagination.size': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'lists.filters.ordering': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 0.5rem;
}
</style>
